import React, { useState, useEffect } from "react";
import "./Search.css"
import Autosuggest from 'react-autosuggest';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Grid, IconButton, Paper } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import {
  NavLink,    
} from "react-router-dom";

const people = [
    {
      first: 'Charlie',
      last: 'Brown',
      twitter: 'dancounsell'
    },
    {
      first: 'Charlotte',
      last: 'White',
      twitter: 'mtnmissy'
    },
    {
      first: 'Chloe',
      last: 'Jones',
      twitter: 'ladylexy'
    },
    {
      first: 'Cooper',
      last: 'King',
      twitter: 'steveodom'
    }
  ];
  
  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  
  function getSuggestionValue(suggestion) {
    return `${suggestion.materialName}`;
  }
  
  // function renderSuggestion(suggestion, { query }) {
  //   const suggestionText = `${suggestion.first} ${suggestion.last}`;
  //   const matches = match(suggestionText, query);
  //   const parts = parse(suggestionText, matches);
  
  //   return (
  //     <span className={'suggestion-content ' + suggestion.twitter}>
  //       <span className="searchname">
  //         {
  //           parts.map((part, index) => {
  //             const className = part.highlight ? 'highlight' : null;
  
  //             return (
  //               <span className={className} key={index}>{part.text}</span>
  //             );
  //           })
  //         }
  //       </span>
  //     </span>
  //   );
  // }
  
  class Search extends React.Component {
    constructor() {
      super();
  
      this.state = {
        value: '',
        suggestions: [],
        allMaterials: []
      };    
    }

    componentDidMount() {
      if (this.props.material != null) {
        console.log(this.props.material)
        this.setState({allMaterials: this.props.material})
      }
    }

    componentWillReceiveProps(nextProps) {
      // You don't have to do this check first, but it can help prevent an unneeded render
      // if (nextProps.startTime !== this.state.startTime) {

        console.log(nextProps.material)
        if (nextProps.material != null) {
          this.setState({ allMaterials: nextProps.material });
        }
      // }
    }

    getSuggestions = (value) => {
      let escapedValue = escapeRegexCharacters(value.trim());
      
      if (escapedValue === '') {
        return [];
      }
    
      let regex = new RegExp('\\b' + escapedValue, 'i');
      
      return this.state.allMaterials.filter(mat => regex.test(mat.materialName));
    }

    renderSuggestion = (suggestion, { query }) => {
      const suggestionText = `${suggestion.materialName}`;
      const matches = match(suggestionText, query);
      const parts = parse(suggestionText, matches);
    
      return (
        <NavLink 
                to={"/course?examId=" + suggestion.examId + "&chapterId=" + suggestion.chapId + "&materialId=" + suggestion.matId}
                >
        <span className={'suggestion-content ' + suggestion.twitter}>
          <span className="searchname">
            {
              parts.map((part, index) => {
                const className = part.highlight ? 'highlight' : null;
    
                return (
                  <span className={className} key={index}>{part.text}</span>
                );
              })
            }
          </span>
        </span>
        </NavLink>
      );
    }
  
    onChange = (event, { newValue, method }) => {
      this.setState({
        value: newValue
      });
      console.log(newValue)
      // this.props.history.push('/course?examId=' + )
    };
    
    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    };
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };
  
    onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
      event.preventDefault();
      // console.log('ping... ', suggestion)
      // this.props.router.push('/course?examId=' + suggestion.examId + "&chapterId=" + suggestion.chapId + "&materialId=" + suggestion.matId)
  }

    render() {
      const { value, suggestions } = this.state;
      const inputProps = {
        placeholder: "Search for materials",
        value,
        onChange: this.onChange
      };
  
      return (
        <Grid xs={12} item container style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
        }}>
            <Grid item container xs={12}>
            <Paper elevation={1} container direction="row" className="paper-search">
                <Grid container style={{width: "100%"}} direction="row">
                <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <IconButton>
                        <SearchIcon color="primary"/>
                    </IconButton>
                </Grid>
                <Grid xs={9} item >
                <Autosuggest 
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                // onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps} />
                </Grid>
                </Grid>
            </Paper>
            </Grid>
        </Grid>
      );
    }
  }
  
export default Search;