import { CheckBox } from '@mui/icons-material';
import { Button, FormControlLabel, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


import {firebaseAuth, fireStore} from '../../config/firebase-config';

import './login.css'
import Features from './Features';
import Checkout from './Checkout';

function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")


    const [fullName, setFullName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [referralCode, setReferralCode] = useState("")

    const [uid, setUid] = useState(null)

    const [state, setState] = useState("signin")

    const location = useLocation();
  
    const history = useHistory();

    useEffect(() => {
    //    console.log(location.pathname); // result: '/secondpage'
    //    console.log(location.search); // result: '?query=abc'
    //    console.log(location.state); // result: 'some_value'
       console.log("State", location.state); 
        if (location.state != null && location.state.uid != null) {
            setUid(location.state.uid)
            setState(
                location.state.state == 0 
                ? "register"
                : location.state.state == 1
                ? "features"
                : "signin"
            )
        }
    }, [location]);


    const onFinish = values => {
        console.log('Success:', values);
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      let option = 1;

      const performAuthentication = async () => {
          const studentDoc = await fireStore().collection("students").where('email', '==', email).get();
          console.log("SUEE", studentDoc.size, email)
          let user = null
          studentDoc.forEach((e) => {
            user = e.data()
            console.log("COW",e.id)
          })
          if (studentDoc.size != 0) {
              firebaseAuth().signInWithEmailAndPassword(email, password)
              .then(resp => {
                  console.log(resp, user)
                  setUid(resp.user.uid)
                  if (user.state == 0) {
                      setState("register") 
                  } else if (user.state == 1) {
                    setState("features") 
                  } else {
                    history.push("/")
                  }
                })
              .catch(err => {
                  console.log(err)
              })
          } else {
            firebaseAuth().createUserWithEmailAndPassword(email, password)
            .then(resp => {
                console.log(resp.user.uid)
                const now = Date.now();
                fireStore().collection("students").doc(resp.user.uid).set({
                    email: email,
                    createdAt: now,
                    state: 0,
                    featuresLocked: true,
                    features: [],
                    paymentStatus: 0
                });
                setState("register")
                setUid(resp.user.uid)
            }
            )
            .catch(err => {
                console.log(err)
            })
          }
      } 

    const performRegistration = async () => {
        fireStore().collection("students").doc(uid).update({
            fullName: fullName.trim().length == 0 ? null : fullName,
            phoneNumber: phoneNumber.trim().length == 0 ? null : phoneNumber,
            referralCode: referralCode.trim().length == 0 ? null : referralCode,
            state: 1
        });
        setState("features")
    }
      
    return (
        <div className="login-page">
        { state != "features" 
          ? <div className="login-box">
            <div className="illustration-wrapper">
              <img src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700" alt="Login"/>
            </div>
            <FormContainer
            //   name="login-form"
            //   initialValues={{ remember: true }}
            //   onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            >
              <FormTitle className="form-title">
                  {state == "signin" ? "Welcome back" : state == "register" ? "Please enter the details" : null}
                </FormTitle>
              <p>Login to the Dashboard</p>
              {/* <TextField
                label="Size"
                id="filled-size-normal"
                defaultValue="Normal"
                variant="filled"
                />
    
            <TextField
                label="Size"
                id="filled-size-normal"
                defaultValue="Normal"
                variant="filled"
                />
    
              <FormControlLabel control={<CheckBox defaultChecked />} label="Label" />
    
              <Button variant="contained">LOGIN</Button> */}
              {
                  state == "signin"
                ? <form className='account-form' onSubmit={(evt) => evt.preventDefault()}>
                    <div className={'account-form-fields ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot')) }>
                        <input id='email' name='email' type='email' placeholder='E-mail' value={email} onChange={(e) => {setEmail(e.target.value)}} required />
                        <input id='password' name='password' type='password' placeholder='Password' value={password} onChange={(e) => {setPassword(e.target.value)}} required={option === 1 || option === 2 ? true : false} disabled={option === 3 ? true : false} />
                        {/* <input id='repeat-password' name='repeat-password' type='password' placeholder='Repeat password' required={option === 2 ? true : false} disabled={option === 1 || option === 3 ? true : false} /> */}
                    </div>
                    <button className='btn-submit-form' onClick={() => {performAuthentication()}}>
                        { option === 1 ? 'Sign in' : (option === 2 ? 'Sign up' : 'Reset password') }
                    </button>
                </form>
                : state == "register" 
                ? <form className='account-form' onSubmit={(evt) => evt.preventDefault()}>
                <div className={'account-form-fields ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot')) }>
                    <input id='fullname' name='fullname'  placeholder='Full name' value={fullName} onChange={(e) => {setFullName(e.target.value)}} required />
                    <input id='phno' name='phno'  placeholder='Phone number' value={phoneNumber} onChange={(e) => {setPhoneNumber(e.target.value)}} required />
                    <input id='refcode' name='refcode'  placeholder='Referral Code' value={referralCode} onChange={(e) => {setReferralCode(e.target.value)}} />
                    {/* <input id='repeat-password' name='repeat-password' type='password' placeholder='Repeat password' required={option === 2 ? true : false} disabled={option === 1 || option === 3 ? true : false} /> */}
                </div>
                <button className='btn-submit-form' onClick={() => {
                    performRegistration()
                    }}>
                    Submit
                </button>
            </form>
                : null
                }
            </FormContainer>
          </div>
          : <div className="login-box">
              <div style={{display: "flex", flexDirection: "row"}}>
                <Features />
                <Checkout uid={uid} />
              </div>
          </div>
        }
        </div>
      );
}

const FormContainer = styled.div`
flex: 1 0 100%;
    max-width: 480px;
    width: 100%;
    padding: 60px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
`;


const FormTitle = styled.p`
color: #333333;
    font-family: Nunito, sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
`;

export default Login;

