import React from 'react';
import YouTube from 'react-youtube';
import './YoutubeStyles.css'

export default function YoutubeVideoPlayer(props) {
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.stopVideo();
      }
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        },
    };
  return <YouTube 
            className="inner-class-extend"
            containerClassName="full-extend"
            videoId={props.videoUrl}
            opts={opts}
            onReady={_onReady} 
            onEnd={() => {props.onEnd != null && props.onEnd()}}
            />;

  
}
