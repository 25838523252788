
import firebase from "firebase/app";
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyDVDUHOdHpA8ryNiOa99BAECXE_G-PyJ5M",
    authDomain: "masters-toolkit.firebaseapp.com",
    databaseURL: "https://masters-toolkit-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "masters-toolkit",
    storageBucket: "masters-toolkit.appspot.com",
    messagingSenderId: "529302631986",
    appId: "1:529302631986:web:55f5d2c988dee311a2ae2b",
    measurementId: "G-YVEZ9MW5Y0"
  };

firebase.initializeApp(firebaseConfig);
const storage= firebase.storage();
const firestore= firebase.firestore();
const firebaseauth= firebase.auth()
//export default firestore;
export const firebaseStorage = () => storage;
export const fireStore = () => firestore;
export const firebaseAuth = () => firebaseauth;