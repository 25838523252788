import React, {useEffect, useState} from "react";
import Course1 from "../ui/course-1.png";
import Course2 from "../ui/course-2.jpg";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { ArrowRightRounded } from "@mui/icons-material";

import './home.css'
import { makeStyles } from '@mui/styles';

import useSettings from '../hooks/useSettings'
import useAuth from '../hooks/useAuth';

import {
    NavLink,    
} from "react-router-dom";
import {firebaseAuth, fireStore} from '../config/firebase-config';
import firebase from "firebase";

import math from "../ui/math.jpg";
import bgcourse from "../ui/bgcourse.jpg";

import Search from "../components/Search/Search";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
    root : {
      '&:hover': {
        boxShadow: " 0px 0px 30px -9px rgba(0,0,0,0.71)",
        transform: "scale3d(1.01, 1.01, 1)",
        transition: "transform 0.2s ease-in-out"
     },
    }
      
  });

function HomePage(){

    const [exams, setExams] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [activeExam, setActiveExam] = useState(null);
    
    const [allMaterials, setAllMaterials] = useState(null)
    
    const { settings, updateSettings } = useSettings()

    const {user, updateUser} = useAuth()

    const classes = useStyles();

    const history = useHistory();

    const decidePageAfterLogin = async (uid) => {
        const userData = await fireStore().collection("students").doc(uid).get();
        console.log("Userdata", userData.data(), history)
        if (userData.data().state == 0 || userData.data().state == 1 || userData.data().state == 2) {
            history.push({
                pathname: '/login',
                state: { state: userData.data().state, uid: uid }
            })
        } else {
            // history.push("/course")
        }
    } 

    useEffect(() => { 
        
        let mode = "open"
        updateSidebarMode( {mode})
        firebaseAuth().onAuthStateChanged(function(user) {
            if (user) {
                console.log('This is the user: ', user.uid, user)
                decidePageAfterLogin(user.uid);
                updateUser({
                    uid: user.uid,
                    email: user.email
                })
            } else {
                // No user is signed in.
                console.log('There is no logged in user');
                history.push("/login")
            }
        });
    }, [])


    useEffect(()=>{
        document.title = "Masters Course App";
        fetchExams();
    }, [])

    
    const updateSidebarMode = (sidebarSettings) => {
        updateSettings({
            layout1Settings: {
                leftSidebar: {
                    ...sidebarSettings,
                },
            },
        })
    }

    useEffect(()=>{
        fetchChapters(activeExam)
    },[activeExam])

    const fetchExams = async () => {
        let examList = [];
        let examsDB = await fireStore().collection("exam").get();
        examsDB.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
            examList.push({...doc.data(), id: doc.id});
        });
        setExams(examList);
        if (examList != null && examList.length > 0) {
            setActiveExam(examList[0]['id'])
            let tempMaterials = []
            let chaptersDB = await fireStore().collection('chapters').where('examId', '==', examList[0]['id']).orderBy('createdAt', 'asc').get();
            if (!chaptersDB.empty) {
                let tempChapters = []
                chaptersDB.forEach(e => {
                  tempChapters.push({
                    ...e.data(),
                    id : e.id
                  })
                  if (e.data().materials != null) {
                    e.data().materials.forEach((mate, mateIn) => {
                        tempMaterials.push(
                            {
                                ...mate,
                                chapId: e.id,
                                examId: examList[0]['id'],
                                matId: mateIn
                            }
                        )
                    })
                }
                })
                setChapters(tempChapters)
                setAllMaterials(tempMaterials)
                console.log(tempChapters)
            }
        } 
    }

    const fetchChapters = async (examId) => {
        let chaptersDB = await fireStore().collection('chapters').where('examId', '==', examId).orderBy('createdAt', 'asc').get();
            if (!chaptersDB.empty) {
                let tempChapters = []
                chaptersDB.forEach(e => {
                  tempChapters.push({
                    ...e.data(),
                    id : e.id
                  })
                })
                setChapters(tempChapters)
                console.log(tempChapters)
            }
    }

    const [popularCourse, setPopularCourse] = useState([
        {
            ID: 1,
            title: "Learning How to Create Beautiful Scenes in Illustrator in 60 minutes",
            tutor: {
                ID: 1,
                name: "Lana Marandina",
                username: "lanamara",
                dp: "http://placeimg.com/100/100/people?tutor-" + 1,
            },
            duration: "82 min",            
            poster: Course1
        },
        {
            ID: 2,
            title: "Creating a beautiful Portrait Illustration. Learning new Technics and Tricks.",
            tutor: {
                ID: 2,
                name: "Uran Design",
                username: "urancd",
                dp: "http://placeimg.com/100/100/people?tutor-" + 2,
            },
            duration: "1 hr 13 min",            
            poster: Course2
        },
        {
            ID: 3,
            title: "Creating a beautiful Portrait Illustration. Learning new Technics and Tricks.",
            tutor: {
                ID: 3,
                name: "Uran Design",
                username: "urancd",
                dp: "http://placeimg.com/100/100/people?tutor-" + 2,
            },
            duration: "1 hr 13 min",            
            poster: Course2
        }
    ]);

    const [topTutors, setTopTutors] = useState([
        {
                ID: 1,
                name: "Lana Marandina",
                username: "lanamara",
                dp: "http://placeimg.com/100/100/people?tutors-" + 1,
        },
        {
            ID: 2,
            name: "Lana Marandina",
            username: "lanamara",
            dp: "http://placeimg.com/100/100/people?tutors-" + 2,
        },        
        {
            ID: 3,
            name: "Lana Marandina",
            username: "lanamara",
            dp: "http://placeimg.com/100/100/people?tutors-" + 3,
        },
        {
            ID: 4,
            name: "Lana Marandina",
            username: "lanamara",
            dp: "http://placeimg.com/100/100/people?tutors-" + 4,
        },
        {
            ID: 5,
            name: "Lana Marandina",
            username: "lanamara",
            dp: "http://placeimg.com/100/100/people?tutors-" + 5,
        },        
        {
            ID: 6,
            name: "Lana Marandina",
            username: "lanamara",
            dp: "http://placeimg.com/100/100/people?tutors-" + 6,
        }
    ]);

    //Live Tutors List
    var tutorList = [];
    if (exams != null && exams.length != 0) {
        for(let i = 0; i < exams.length; i++){
            console.log("activeExam", exams[i].id == activeExam)
            tutorList.push(
                <Grid item container style={{display: "flex", width: "fit-content"}}>
                    <Paper 
                        className={`${exams[i].id == activeExam ? "activeExam" : "inactiveExam"}`}
                        classes={classes} 
                        elevation={5} 
                        style={{padding: "9px", borderRadius: "7px", cursor: "pointer"}}
                        onClick={() => {
                            console.log(exams[i].id)
                            setActiveExam(exams[i].id)
                        }}
                        >
                        <img 
                            src={exams[i].examIcon}  
                            className="bl" 
                            style={{
                                width: "180px",
                                height : "60px",
                                display: "flex",
                                objectFit: "contain"
                            }} 
                        />
                    </Paper>
                </Grid>
            );
        }
    }   

    var courseList = [];
    for(let i = 0; i < popularCourse.length; i++){
        courseList.push(
            <NavLink to={"/course/" + popularCourse[i].ID} className="course rel" key={"popular-course-" + i}>
                <div className="block rel" style={{
                    background: "#e2e2e2 url(" + popularCourse[i].poster +") no-repeat center"
                }}>

                    <div className="user abs aic flex">
                        <div className="pic">
                            <img src={popularCourse[i].tutor.dp} className="bl" />
                        </div>
                        <div className="meta rel">
                            <h2 className="s15 name fontb cfff">{popularCourse[i].tutor.name}</h2>
                            <h2 className="s13 uname fontn cfff">@{popularCourse[i].tutor.username}</h2>
                        </div>
                    </div>

                    <div className="dura abs">
                        <h2 className="s13 name fontb cfff">{popularCourse[i].duration}</h2>
                    </div>

                    <div className="course-title abs">
                        <h2 className="s15 name fontb cfff">{popularCourse[i].title}</h2>
                    </div>

                </div>
            </NavLink>
        );
    }

    var topTutorsList = [];
    for(let i = 0; i < topTutors.length; i++){
        topTutorsList.push(
            <a href="#" className="user-block rel noul" key={"top-tutors-" + i}>
                <div className="user aic flex">
                    <div className="pic">
                        <img src={topTutors[i].dp} className="bl" />
                    </div>
                    <div className="meta rel">
                        <h2 className="s15 name fontb c333">{topTutors[i].name}</h2>
                        <h2 className="s13 uname fontn c333">@{topTutors[i].username}</h2>
                    </div>
                </div>                
            </a>
        );
    }

    const hasMaterials = (materials) => {
        if (materials != null && materials.length > 0) {
            return true;
        } else {
            return false
        }
    }

    const randomIntFromInterval = (min, max) => { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      }

      
    const materials = (materials, chapId, examId) => {
        let materialList = []
        let  i = 0
        let j = 0
        if (materials != null && materials.length > 0) {
        materials.every((mat, ind) => {
            materialList.push(
            <NavLink 
                to={"/course?examId=" + examId + "&chapterId=" + chapId + "&materialId=" + ind}
                 className="course rel" key={"popular-course-" + i}
                 onClick={() => {
                    // let mode = "close"
                    // updateSidebarMode( {mode})
                 }}
                 style={{textDecoration: "none", 
                 marginTop: "1rem"}}
                 >
                <div className="block rel imageblur" style={{
                    backgroundImage: `url(${require('../ui/bgcourse-' + (ind % 3 + 1) + ".jpg")})`,
                    backgroundSize: "contain"
                    // background: "#e2e2e2 url(" + popularCourse[i].poster +") no-repeat center"
                }}>

            <div className="material-name">
                {/* <div className="meta rel"> */}
                <Typography component="h3" variant="h4" style={{fontFamily: "Manrope", fontWeight: "bold", color: "#000000", fontSize: "18px", alignItems:"center", display: "flex", }}>
                                {mat.materialName}
                            </Typography>
                {/* </div> */}
            </div>
            
            {/* <div style={{padding : "20px 0px 15px 20px"}}>
                <div className="dura" style={{width: "fit-content"}}>
                    <h2 className="s13 name fontb cfff">{popularCourse[i].duration}</h2>
                </div>
            </div> */}
            {/* <div className="course-title abs">
                <h2 className="s15 name fontb cfff">{popularCourse[i].title}</h2>
            </div> */}

                </div>
            </NavLink>
        )
        j = j + 1
        
         if (j == 3) {
            return false;
        } else {
            return true
        }
        })
    }   
    console.log(materialList)
        return materialList;
    }

    


    return (
        <div className="home-page rel">
            <Grid container style={{width: "100%", justifyContent: "center"}}>
                <Grid item container style={{width: "50%"}}>
                <Search 
                    material = {allMaterials}
                />
                </Grid>
            </Grid>
            {/**Tutors Live Now */}
            <div className="section rel">
                <div className="home">
                {/* <IconButton>
                    <MenuIcon onClick={() => {
                        let mode = "close"
                        console.log("MODE" + settings.layout1Settings.leftSidebar.mode)
                        if (settings != null && settings.layout1Settings.leftSidebar.mode != "open") {
                            mode = "open"
                        }
                        updateSidebarMode( {mode})
                    }}/>
                </IconButton> */}
                <Typography component="h3" variant="h4" style={{fontFamily: "Manrope", fontWeight: "bold", color: "#000000", fontSize: "24px", alignItems:"center", display: "flex", }}>
                                Exams
                            </Typography>
                </div>
                <Grid container direction="row" spacing={3}>
                    {tutorList}
                </Grid>
            </div>

            {/**Popular Courses */}
            
                {
                    chapters != null && chapters.length > 0 
                    ? chapters.map((chap, chapInd) => { 
                                if(hasMaterials(chap.materials)) { 
                                return <div className="section section-b rel">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{width: "0.3rem", backgroundColor: "#2b76d9", marginRight: "0.5rem"}}></div>
                            <Typography component="h3" variant="h4" style={{fontFamily: "Manrope", fontWeight: "bold", color: "#272727", fontSize: "20px", alignItems:"center", display: "flex", }}>
                                {chap.name}
                            </Typography>
                            </div>
                        <div className="courses rel flex">
                            {materials(chap.materials, chap.id, chap.examId)}
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <NavLink 
                to={"/course?examId=" + chap.examId + "&chapterId=" + chap.id + "&materialId=" + 0}
                 className="course rel" key={"popular-course-" + chapInd}
                 onClick={() => {
                    // let mode = "close"
                    // updateSidebarMode( {mode})
                 }}
                 >
                                <IconButton>
                                    <ArrowRightRounded fontSize="large" style={{fontSize: "3rem", color: "#2B76D9"}} />
                                    {/*  onClick={() => {
                                        let mode = "close"
                                        console.log("MODE" + settings.layout1Settings.leftSidebar.mode)
                                        if (settings != null && settings.layout1Settings.leftSidebar.mode != "open") {
                                            mode = "open"
                                        }
                                        updateSidebarMode( {mode})
                                    }} */}
                                </IconButton>
                                </NavLink>
                            </div>
                        </div> 
                    
                        </div>
                                }
                            
                    })
                    : null
                
                }  

            {/**Top Tutors */}
            {/* <div className="section section-b rel">
                <h2 className="title s24 fontb">Top <span  className="fontn">Tutors</span></h2>
                <div className="top-tutors rel flex">
                    {topTutorsList}
                </div>
            </div> */}

        </div>
    )
}

export default HomePage;