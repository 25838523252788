import React from "react";
import logo from '../ui/logo-coral.svg';

function Header(){
    return (
        <div className="header fixed">
            
        

        </div>
    )
}

export default Header;