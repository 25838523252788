import { themeColors } from './themeColors'
import { forEach, merge } from 'lodash'
import themeOptions from './themeOptions'
import { createMuiTheme } from '@mui/material'

function createMatxThemes() {
    let themes = {}
    
    forEach(themeColors, (value, key) => {
        themes[key] = createMuiTheme(merge({}, themeOptions, value))
    })
    return themes
}
export const themes = createMatxThemes()
