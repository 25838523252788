import React, { useEffect, useState } from "react";
import logo from '../ui/logo.svg';
import useSettings from "../hooks/useSettings";

import { useLocation } from 'react-router';
import {
    NavLink,    
} from "react-router-dom";
import './sidebar.css'
import { Button, Grid, Typography } from "@mui/material";
import Logo from "../components/Logo/Logo";
import useAuth from "../hooks/useAuth";

import LogoutIcon from '@mui/icons-material/Logout';
import { firebaseAuth } from "../config/firebase-config";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Sidebar(props){

    const history = useHistory();

    const [nav, setNav] = useState([
        {label: "Home", slug: "/", icon: "icon-home"},
        // {label: "Country", slug: "/discover", icon: "icon-ul"},
        // {label: "Settings", slug: "/settings", icon: "icon-briefcase"}
    ])


    const {settings, updateSettings} = useSettings()
    // const leftSidebar = settings.layout1Settings.leftSidebar
    // const { mode } = leftSidebar

    const {user, updateUser} = useAuth();

    const [currentPage, setCurrentPage] = useState("/");
    const [isClosed, setClosed] = useState(false);
    
    const location = useLocation();
    // let params = queryString.parse(location.search);

    useEffect(() => {
        console.log("LOKII", location)
        if (location.pathname == "/login") {
            setClosed(true)
        } else {
            setClosed(false)
        }
     }, [location])
    useEffect(() => {
        console.log(settings)
    }, [settings])

    var navigation = [];
    for(let i = 0; i < nav.length; i++){
        navigation.push(
            <li key={"nav-" + i + "-" + nav[i].slug}>
                <NavLink to={nav[i].slug} className={`aic link noul flex c333 ${
                    location.pathname == nav[i].slug
                    ? "linkactive"
                    : "linknotactive"
                }`}>
                    <div className={"ico s20 " + nav[i].icon} />
                    <Typography component="h3" variant="h4" style={{fontFamily: "Manrope", fontWeight: "bold", color: "#fff", fontSize: "18px", alignItems:"center", display: "flex", }}>
                        {nav[i].label}
                    </Typography>
                </NavLink>
            </li>
        );
    }

    console.log(global.fire);
    return (
        !isClosed && <div className={`sidebar rel ${
            settings != null 
            && settings.layout1Settings.leftSidebar.mode == "close" 
                ? " sidebarclose" 
                : settings.layout1Settings.leftSidebar.mode == "open" 
                    ? " sidebaropen"
                    : ""}`}>
            {/* <a href="#" className="logo bl">
                <img src={logo} className="bl" />
            </a> */}
            <Grid container direction="row" style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "30px",
                width: "100%"
            }}>
                <Logo />
                <Typography component="h3" variant="h4" style={{fontFamily: "Manrope", fontWeight: "600", color: "#2b2b2b", fontSize: "23px", alignItems:"center", display: "flex", marginLeft: "1rem"}}>
                    OnJobUSA
                </Typography>
            </Grid>

            <ul className="nav">
                {navigation}
            </ul>
{/* 
            <div className="updated-course flex aic">
                <div className="icon-lamp-bright cfff s24 ico" />
                <div className="lbl s15 fontb c333">
                    Updated Courses
                    <h2 className="author s13 c777">by Kamran Wajdani</h2>
                </div>
            </div> */}
{/* 
            <div className="stats aic flex">

                <div className="stats-box flex">
                    <div className="ico ico-points s24 icon-shield" />
                    <h2 className="val s15 c333 fontb">1800</h2>
                    <h2 className="lbl s13 c777">points</h2>
                </div>

                <div className="stats-box flex">
                    <div className="ico ico-battery s24 icon-battery-90" />
                    <h2 className="val s15 c333 fontb">45.3%</h2>
                    <h2 className="lbl s13 c777">complete</h2>
                </div>

            </div> */}


            {/* <div className="me flex aic">
                {global.fire.ID ? <React.Fragment><div className="photo cfff s24">
                    <img src="http://placeimg.com/100/100/people" className="bl" />
                </div>
                <div className="lbl s15 fontb c333">
                    Kamran Wajdani   
                    <h2 className="uname s13 c777">@kamranwajdani</h2>                 
                </div>
                </React.Fragment>
                : 
                <NavLink to={"oauth"} className={"aic link noul flex c333"}>
                    <div className={"ico s24 rel cfff icon-portrait-male"} />
                    <h2 className="lbl s20 fontb">Sign in</h2>
                </NavLink>
                }
            </div> */}

            <div style={{
                    position: "absolute",
                    bottom: "1rem",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
            <Button 
                onClick={() => {
                    if (user!=null) {
                        firebaseAuth().signOut().then(() => {
                            history.push("/login")
                        })
                    }
                }}
                style={{width: "80%", fontFamily: "Manrope"}}
                variant="contained" startIcon={<LogoutIcon />} >
                Logout
            </Button>
            </div>

        </div>
    )
}

export default Sidebar;