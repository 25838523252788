import React from 'react';

import './features.css'

import styled from '@emotion/styled'


function Features() {
return 	<section>
<div class="container-fluid">
<FormTitle>
    Please choose the right package for you
</FormTitle>
  <div class="container">
    <div class="row" style={{display: "flex"}}>
      <div class="col-sm-4" style={{marginRight: "2rem"}}>
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
            <h2>Basic</h2>
          </div>
          <div class="price">
            <h4><sup>&#8377;</sup>200</h4>
          </div>
          <div class="option">
            <ul>
            <li> <i class="fa fa-check" aria-hidden="true"></i> GRE  </li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Listening </li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Writing </li>
            <li> <i class="fa fa-times" aria-hidden="true"></i> Speak </li>
            </ul>
          </div>
          <a href="#">Order Now </a>
        </div>
      </div>
      {/* <!-- END Col one --> */}
      <div class="col-sm-4"  style={{marginRight: "2rem"}}>
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-plane" aria-hidden="true"></i>
            <h2>Standard</h2>
          </div>
          <div class="price">
            <h4><sup>$</sup>50</h4>
          </div>
          <div class="option">
            <ul>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Reading </li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Listening </li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Writing </li>
            <li> <i class="fa fa-times" aria-hidden="true"></i> Speak </li>
            </ul>
          </div>
          <a href="#">Order Now </a>
        </div>
      </div>
      {/* <!-- END Col two --> */}
      <div class="col-sm-4">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Premium</h2>
          </div>
          <div class="price">
            <h4><sup>$</sup>100</h4>
          </div>
          <div class="option">
            <ul>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Reading </li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Listening </li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Writing </li>
            <li> <i class="fa fa-times" aria-hidden="true"></i> Speak </li>
            </ul>
          </div>
          <a href="#">Order Now </a>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
}


const FormTitle = styled.p`
    color: #333333;
    font-family: Nunito, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0;
`;

export default Features