import React, { createContext, useState } from 'react'

const user = {
    userName: null,
    uid: null
}

const AuthContext = createContext({
    user: user,
    updateUser: () => {},
})

export const AuthProvider = ({ settings, children }) => {
    const [currentUser, setCurrentUser] = useState(
        settings || user
    )

    const handleUpdateUser = (update = {}) => {
        console.log("updated", update)
        setCurrentUser(update)
    }

    return (
        <AuthContext.Provider
            value={{
                user: currentUser,
                updateUser: handleUpdateUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
