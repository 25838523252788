import React, { useState, useEffect } from 'react';
import AppContext from "./AppContext";
import logo from "./ui/logo-coral.svg";

import "./css/uifont.css";
import "./css/props.css";
import "./css/App.css";

import {firebaseAuth, fireStore} from './config/firebase-config'

import { useHistory } from "react-router-dom";

import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";


import { useLocation } from 'react-router';

//Screen
import Header from "./screens/header";
import Sidebar from "./screens/sidebar";
import HomePage from "./screens/home";
import Rightbar from "./screens/rightbar";
import CoursePage from "./screens/course";
import DiscoverPage from "./screens/discover";
import CategoriesPage from "./screens/categories";
import MyCoursesPage from "./screens/settings";

import AccountPage from "./screens/oauth";

import * as fire_base from "firebase";
import useSettings from '../src/hooks/useSettings'
import Login from './screens/authentication/Login';
import { BrowserRouter } from 'react-router-dom';
import Settings from './screens/settings';

global.firebase = fire_base;
global.fire = {
    ID: null
};
// var firebaseConfig = {
//   apiKey: "YOUR_API_KEY",
//   authDomain: "YOUR_AUTH_DOMAIN",
//   databaseURL: "YOUR_DB_URL",
//   projectId: "YOUR_PROJECT_ID",
//   storageBucket: "YOUR_STORAGE_BUCKET",
//   messagingSenderId: "YOUR_SENDER_ID",
//   appId: "YOUR_APP_ID"
// };
// Initialize Firebase
// global.firebase.initializeApp(firebaseConfig);



export default function AppLoader(){

    const history = useHistory();

    const [isFireUser, setIsFireUser] = useState(false);

    const { settings, updateSettings } = useSettings()
    const { showSidebar, updateSidebarMode } = useState(false)

    // const initFirebase = async (context) => {
    //     global.firebase.auth().onAuthStateChanged((user)=>{
    //       if(user){
    //           console.log("You are signed in...")
    //           setIsFireUser(true);
    //       }else{
    //         console.log("You are guest...");
    //         setIsFireUser(false);
    //         setTimeout(()=>{
    //             context.setAppLoaded(true);
    //         }, 500);
    //       }
    //     });
    // }
    
    const splash = (context) => {
        return (
            <div className="App flex">      
                <div className="splash abs abc">
                    <img src={logo} className="bl" />
                </div>
            </div>
        )
    }

    const loadApp = async (context) => {
        // await initFirebase(context);
        context.setAppLoaded(true);
    }

    
    return (
        <AppContext.Consumer>
            {
                context => {
                    return (
                        context.appLoaded() ? 
                        <div className="App flex">  
                            <BrowserRouter basename='/learn' > 
                                <div style={{
                                    display: "flex",
                                    position: "relative",
                                    overflow: "hidden",
                                    width: "100%"
                                }}>
                                    <Sidebar closed={showSidebar}/>
                                    <div className="app-content">
                                        <Route exact path="/" component={HomePage} />
                                        <Route path="/course" component={CoursePage} />
                                        <Route path="/discover" component={DiscoverPage} />
                                        <Route path="/cates" component={CategoriesPage} />
                                        <Route path="/settings" component={Settings} />
                                        <Route path="/oauth" component={AccountPage} />
                                        <Route exact path="/login" component={Login} />
                                    </div>  
                                </div>  
                            </BrowserRouter> 
                            
                        </div>
                        : 
                        <AppContext.Consumer>
                            {
                                context => {
                                    loadApp(context);
                                    return (splash(context))
                                }
                            }
                        </AppContext.Consumer>
                    )
                }
            }
        </AppContext.Consumer>
    )

}
