import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import barLoader from '../../ui/loaders/bars.svg';
import "./document-editor.css"
import { Grid } from '@mui/material';

const TinyMceEditor = (props) => {
  const editorRef = useRef(null);
  const [isLoading, setLoading] = useState(true);

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const handleEditorChange = (editorContent) => {
    console.log(editorContent)
    props.save(editorContent);
  }
  return (
    <div className="document-editor">
      <Editor
        apiKey="e7r6e2dn0pm8u3mhx51tekl2g83bq2i4ioqsftka4nmtfepl"
        onPostRender={()=> {setLoading(false)}}
        onInit={(evt, editor) => {
          editorRef.current = editor
          console.log("done")
          setLoading(false)
        }}
        disabled = {true}
        value= {props.content}
        init={{
          readonly: 1,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount textpattern'
          ],
          // selector: 'textarea', // change this value according to your HTML
          // // The `lists` plugin is required for list-related text patterns
          // plugin: 'textpattern lists preview',
          textpattern_patterns: [
            {start: '#', format: 'h1'},
            {start: '##', format: 'h2'},
            {start: '###', format: 'h3'},
            {start: '####', format: 'h4'},
            {start: '#####', format: 'h5'},
            {start: '######', format: 'h6'},
            {start: '* ', cmd: 'InsertUnorderedList'},
            {start: '- ', cmd: 'InsertUnorderedList'},
            {start: '1. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'decimal' }},
            {start: '1) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'decimal' }},
            {start: 'a. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-alpha' }},
            {start: 'a) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-alpha' }},
            {start: 'i. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-roman' }},
            {start: 'i) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-roman' }}
          ],
          toolbar: 'preview ',
          content_css: "document",
          skin: "fabric",
          icons: "jam",
          resize: false
        }}
        onEditorChange={() => {}}
      />
      {
      isLoading 
      && <Grid container style={{width: "100%", height: "100%", display:"flex", justifyContent: "center"}}>
          <img style={{width: "10%"}} src={barLoader} />
        </Grid>
      }
    </div>
  );
}

export default TinyMceEditor;