import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router';
import queryString from 'query-string';

import {firebaseAuth, fireStore} from '../config/firebase-config';
import firebase from "firebase";

import Course1 from "../ui/course-1.png";
import Course2 from "../ui/course-2.jpg";
import { Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, TextField } from "@mui/material";

import YoutubeVideoPlayer from '../components/Player/YoutubeVideoPlayer'
import { styled } from '@mui/material/styles';


import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import PlayCircleFilledWhiteSharpIcon from '@mui/icons-material/PlayCircleFilledWhiteSharp';
import StickyNote2SharpIcon from '@mui/icons-material/StickyNote2Sharp';
import DisabledByDefaultSharpIcon from '@mui/icons-material/DisabledByDefaultSharp';
import WorkIcon from '@mui/icons-material/Work';

import TinyMceEditor from '../components/Editor/TinyMceEditor';

import './course.css'
import { makeStyles } from "@mui/styles";
import Search from "../components/Search/Search";


import useSettings from '../hooks/useSettings'


import CircularProgress from '@mui/material/CircularProgress';


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { KeyboardArrowRight } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import CircularStatic from "../components/Progress/CircularStatic";
import LinearProgressBar from "../components/Progress/LinearProgressBar";
import { NavLink } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Vimeo from '@u-wave/react-vimeo';

import completeRest from '../ui/complete_rest.svg'

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useAuth from "../hooks/useAuth";


import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    borderRight: 0
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    //   expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
      minHeight: "0px",
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
    //   marginLeft: theme.spacing(1),
    margin: 0
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0
  }));
  
  const formLabelStyle = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    label : {
        width: "100%"
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function CoursePage(props){

    
    const history = useHistory();

    const [examId, setExamId] = useState(null)
    const [examName, setExamName] = useState("")
    const [chapterId, setChapterId] = useState(null)
    const [materialId, setMaterialId] = useState(null)
    const [material, setMaterial] = useState(null)
    const [exercise, setExercise] = useState(null)
    const [exercisesSet, setTotalExercisesSet] = useState(null)
    const [allChapters, setChapters] = useState(null)
    const [allMaterials, setAllMaterials] = useState(null)
    const [allChaptersDict, setChaptersDict] = useState(null)
    const [exerciseIndex, setExerciseIndex] = useState(null)
    const [totalExercises, setTotalExercises] = useState(null)
    const [userProgress, setUserProgress] = useState(null)
    
    const [examProgress, setExamProgress] = useState(0)


    const [expanded, setExpanded] = React.useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    
    const { settings, updateSettings } = useSettings()

    const {user, updateUser} = useAuth()

    
    const [userCurrent, updateUserCurrent] = useState(null)


    const formLabelClass = formLabelStyle();

    const handleChange = (panel) => (event, newExpanded) => {
        console.log(newExpanded, panel, expanded)
        let tempExpanded =  [...expanded] 
        let index = tempExpanded.indexOf(panel);
        if (newExpanded == true) {
            tempExpanded.push(panel)
        }
        else if (index != -1 && newExpanded == false) {
            tempExpanded.splice(index, 1)
        } 
        setExpanded(tempExpanded);
        console.log(newExpanded, panel, tempExpanded)
    };


    

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    
    const updateSidebarMode = (sidebarSettings) => {
        updateSettings({
            layout1Settings: {
                leftSidebar: {
                    ...sidebarSettings,
                },
            },
        })
    }

    const [course, setCourse] = useState(
        {
            ID: 1,
            title: "Learning How to Create Beautiful Scenes in Illustrator in 60 minutes",
            about: "In this course i'll teach you how to make your illustration look really good. We're gonna study a lot of cases here, setting your work panel, makingg some awesome actions and presets for future.<br />Hope you'll get some really usefull stuff out of this course. Good Luck to all!",
            tutor: {
                ID: 1,
                name: "Lana Marandina",
                username: "lanamara",
                dp: "http://placeimg.com/100/100/people?tutor-" + 1,
            },
            duration: "82 min",            
            poster: Course1,

            videos: [
                {
                    ID: 1,
                    title: "Introduction",
                    duration: "03 min 24 secs"                    
                },
                {
                    ID: 2,
                    title: "Getting Started",
                    duration: "09 min 55 secs"                    
                },
                {
                    ID: 1,
                    title: "The Illustration",
                    duration: "62 mins 48 secs"                    
                }
            ]
        }
    );

    
    const location = useLocation();
    let params = queryString.parse(location.search);


    useEffect(() => {
        console.log("USERme", user)
        console.log(params)
        let mode = "close"
        updateSidebarMode( {mode})
        firebaseAuth().onAuthStateChanged(function(userfire) {
            if (userfire) {
                console.log('This is the user: ', userfire.uid, userfire)
                // decidePageAfterLogin(userfire.uid);
                updateUser({
                    uid: userfire.uid,
                    email: userfire.email
                })
                
                updateUserCurrent({
                    uid: userfire.uid,
                    email: userfire.email
                })

                console.log("USERinqu", userfire.uid, userCurrent)
                // if (params != null) {
                //     setExamId(params.examId)
                //     setChapterId(params.chapterId)
                //     setMaterialId(params.materialId)
                //     fetchExamDet(params.examId)
                //     fetchMaterial(params.chapterId, params.materialId)
                //     fetchChapters(params.examId)
                // }
            } else {
                // No user is signed in.
                console.log('There is no logged in user');
                history.push("/login")
            }
        });
        
    }, [])

    useEffect(() =>   {
        console.log(params)
        let mode = "close"
        updateSidebarMode( {mode})
        firebaseAuth().onAuthStateChanged(function(userfire) {
            if (userfire) {
                console.log('This is the user: ', userfire.uid, userfire)
                // decidePageAfterLogin(userfire.uid);
                updateUser({
                    uid: userfire.uid,
                    email: userfire.email
                })

                updateUserCurrent({
                    uid: userfire.uid,
                    email: userfire.email
                })
                
                console.log("USERinqu", userfire.uid)
                console.log("USERinqumefa", user, userCurrent)
                // if (params != null) {
                //     setExamId(params.examId)
                //     setChapterId(params.chapterId)
                //     setMaterialId(params.materialId)
                //     fetchExamDet(params.examId)
                //     fetchMaterial(params.chapterId, params.materialId)
                //     fetchChapters(params.examId)
                    
                // }
            } else {
                // No user is signed in.
                console.log('There is no logged in user');
                history.push("/login")
            }
        });
        
    }, [params.chapterId, params.materialId])


    useEffect(( ) => {
        firebaseAuth().onAuthStateChanged(function(userfire) {
            if (userfire) {
                console.log("QQQ")
            } else {
                // No user is signed in.
                console.log('There is no logged in user');
                history.push("/login")
            }
        });
        if (params != null && user!= null && user.uid != null) {
            setExamId(params.examId)
            setChapterId(params.chapterId)
            setMaterialId(params.materialId)
            fetchExamDet(params.examId)
            fetchMaterial(params.chapterId, params.materialId)
            fetchChapters(params.examId)
        }
    },[user])

    const fetchUserProgress = async (userId, mats) => {
        console.log("CLU",userId, mats)
        let currentUser = await fireStore().collection("students").doc(userId).get();
        console.log("SUEE", currentUser.data())
        if (currentUser.data().courseProgress != null) {
            
            console.log("SUEE", currentUser.data().courseProgress)
            setUserProgress(currentUser.data().courseProgress)
            if (currentUser.data().courseProgress.totalVideoProgress != null) {
                setExamProgress((Object.keys(currentUser.data().courseProgress.totalVideoProgress).length/mats.length) * 100)
                console.log("EXPRO", (Object.keys(currentUser.data().courseProgress.totalVideoProgress).length/mats.length) * 100)
            }
        }

    }

    // useEffect(() => {
    //     if (userProgress != null) {
    //         setExamProgress((userProgress/allMaterials.length) * 100)
    //         console.log("EXam pro", (userProgress/allMaterials.length) * 100)
    //     }
    // }, [allMaterials])

    const saveUserProgress = (tempUserProgress) => {
        console.log("SAVING USER PROGRESS", user.uid, tempUserProgress)
        fireStore().collection("students").doc(user.uid).update({
            "courseProgress" : tempUserProgress
        })
    }

    const fetchExamDet = async (examId) => {
        let examDB = await fireStore().collection('exam').doc(examId).get();
        if (!examDB.empty) {
            let tempExam = examDB.data()
            // setChapters(tempChapters)
            // console.log(tempChapters.materials[pmaterialId])
            setExamName(tempExam.name)
        }
    }

    const fetchMaterial = async (pchapterId, pmaterialId) => {
        let chaptersDB = await fireStore().collection('chapters').doc(pchapterId).get();
        if (!chaptersDB.empty) {
            let tempChapters = chaptersDB.data()
            // setChapters(tempChapters)
            // console.log(tempChapters.materials[pmaterialId])
            setMaterial(tempChapters.materials[pmaterialId])
        }
    }

    const fetchChapters = async (examId) => {
        let chaptersDB = await fireStore()
                                .collection("chapters")
                                .where('examId', '==', examId)
                                .orderBy('createdAt', 'asc')
                                .get();

        let tempMaterials = []
        if (!chaptersDB.empty) {
            let tempChapters = []
            let chapDict = {}
            let ind = 0;
            chaptersDB.forEach((e) => {
                console.log(e.id, params.chapterId, ind)
                if (e.id == params.chapterId) {
                    setExpanded([`panel${ind + 1}`])
                }
                tempChapters.push({
                ...e.data(),
                id : e.id
                })
                chapDict[e.id] = {
                    ...e.data(),
                    id : e.id
                    }
                ind = ind + 1
                if (e.data().materials != null) {
                    e.data().materials.forEach((mate, mateIn) => {
                        tempMaterials.push(
                            {
                                ...mate,
                                chapId: e.id,
                                examId: examId,
                                matId: mateIn
                            }
                        )
                    })
                }
            })
            console.log("CHAPDICT", chapDict)
            setChapters(tempChapters)
            setChaptersDict(chapDict)
            setAllMaterials(tempMaterials)
        }
        console.log("USERIN", user)
        fetchUserProgress(user.uid, tempMaterials)
    }

    const renderChapters = () => {
        let accArr = []
        if (allChapters != null) {
        allChapters.forEach( (chap, chapIn) => {
            chapIn = chapIn + 1
            let mats = []
            let allMats = chap.materials
            if (allMats != null) {
                allMats.forEach( (mat, matIn) => {
                    // if (materialId == matIn && chap.id == chapterId && !expanded.includes("panel" + chapIn))
                    mats.push(
                        <Container 
                            className={materialId == matIn && chap.id == chapterId
                                ? "accordian-material-container-active"
                                : "accordian-material-container"}
                            onClick={() => {
                                setTotalExercisesSet(null)
                                setExercise(null)
                                setExerciseIndex(null)
                                setTotalExercises(null)
                                setMaterial(chap.materials[matIn]);
                                setChapterId(chap.id);
                                setMaterialId(matIn);}}
                            >
                                <IconButton>
                                    {
                                        userProgress != null && userProgress["totalVideoProgress"] != null
                                        && userProgress["totalVideoProgress"][`${examId}_${chap.id}_${matIn}`] != null
                                        && userProgress["totalVideoProgress"][`${examId}_${chap.id}_${matIn}`] == true
                                        ? <CheckCircleIcon style={{ color: '#2b76d9' }}/>
                                        : mat.materialType != undefined && mat.materialType == "video"  
                                           ? <PlayCircleFilledWhiteSharpIcon />
                                           : mat.materialType != undefined && mat.materialType == "text" 
                                           ? <StickyNote2SharpIcon />
                                           : <DisabledByDefaultSharpIcon />
                                    }
                                </IconButton>
                            <Typography component="h3" variant="h4" style={{fontFamily: "Manrope", fontWeight: "bold", color: "#2b2b2b", fontSize: "15px", alignItems:"center", display: "flex", }}>
                                {mat.materialName}
                            </Typography>
                        </Container>
                    )
                })
            }
            if (chap.exercises != null) {
                mats.push(
                <Container 
                    className={materialId == chapterId + "exercise" && chap.id == chapterId
                        ? "accordian-material-container-active"
                        : "accordian-material-container"}
                    onClick={() => {
                        console.log("EXERCISE THIS IS")
                        setMaterial("exercise");
                    setTotalExercisesSet(chap.exercises)
                    setExercise(chap.exercises[0])
                    setExerciseIndex(0)
                    setTotalExercises(chap.exercises.length)
                    setChapterId(chap.id);setMaterialId(chapterId + "exercise");}}
                    >
                        <IconButton>
                            {
                                <WorkIcon />
                            }
                        </IconButton>
                    <Typography component="h3" variant="h4" style={{fontFamily: "Manrope", fontWeight: "bold", color: "#2b2b2b", fontSize: "15px", alignItems:"center", display: "flex"}}>
                        {"Exercise"}
                    </Typography>
                </Container>)
            }
            accArr.push(
                <Accordion
                    
                    expanded={expanded.includes('panel' + chapIn)} 
                    onChange={handleChange('panel' + chapIn)}>
                    <AccordionSummary className="accordS" aria-controls={"panel"+chapIn+"d-content"} id={"panel"+chapIn+"d-header"} style={{backgroundColor: "#fff", paddingLeft: 0}}>
                    <div id="anim"></div>
                    <Typography style={{fontFamily: "Manrope", fontWeight: "bold", color: "#2b76d9", fontSize: "17px", padding: "1rem"}}>{chap.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {mats}
                    </AccordionDetails>
                </Accordion>
            )
        })
    }
        return accArr
    }

    const courseID = props.match.params.courseid;

    var courseVideos = [];


    for(let i = 0; i < course.videos.length; i++){
        courseVideos.push(
            <a href="#" key={"course-video-" + i} className="noul aic rel flex">
                <div className="id s18 fontn cfff">{course.videos[i].ID}</div>
                <div className="meta rel">
                    <h1 className="s15 lbl fontb c333">{course.videos[i].title}</h1>
                    <h1 className="s13 dur fontn c777">{course.videos[i].duration}</h1>
                </div>
            </a>            
        );
    }

    const isMaterialCompleted = () => {
        console.log("MATERIALCOMPLETE", chapterId, examId, userProgress)
        if (allChaptersDict[chapterId] != null 
            && allChaptersDict[chapterId].materials != null
            && userProgress != null
            && userProgress[examId] != null 
            && userProgress[examId][chapterId] != null) {
            let tempMats = allChaptersDict[chapterId].materials;
            let tempProgressMats = userProgress[examId][chapterId]
            console.log(tempMats, tempProgressMats)
            let progressLeft = true
            tempMats.forEach((m, mId) => {
                if (!(tempProgressMats[mId] != null && tempProgressMats[mId]["hasWatched"] == true)) {
                    progressLeft = false;
                    return false
                }
            })
            if (progressLeft) {
                return true
            }
        } 
        return false
    }
    
    return (
        <div className="course-page" style={{overflow: "hidden", height: "100vh"}}>
            

            <Grid container style={{overflow: "hidden",  height: "100vh"}}> 
            <Grid item container xs={3.5} spacing={0} style={{
                    flexDirection: "column", 
                    overflow: "scroll", 
                    borderRightWidth: "1px", 
                    borderRightStyle: "solid", 
                    borderRightColor: "rgba(0, 0, 0, 0.12)"
                }}>

               {/* Insert here */}  

                <div style={{height: "30vh", width: "100%", padding: "1.5rem"}}>
                    <Paper elevation={0} spacing={0} style={{
                        borderRadius: "1rem",
                        height: "100%", 
                        width: "100%", 
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "rgba(243, 244, 246, 1)", 
                        boxShadow: "box-shadow: 23 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);", 
                        borderColor: "#e5e7eb",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", position: "relative"}}>
                                <NavLink style={{display: "flex", position: "absolute", left: 0, top: "1.27rem"}} to={"/"} className="course rel" key={"/"}>
                                    <IconButton style={{padding: 0}}>
                                        <HomeIcon />
                                    </IconButton>
                                </NavLink>
                                <Typography style={{fontFamily: "Manrope", fontWeight: "1000", color: "rgba(75, 85, 99)", fontSize: "21px", padding: "1rem"}}>{examName}</Typography>
                            </div>
                        <div style={{width: "100%", display: "flex", alignItems: "center", flexDirection: "column", marginTop: "1rem"}}>
                            <LinearProgressBar value={
                                    Math.floor(examProgress)
                                } />
                            <Typography style={{fontFamily: "Manrope", fontWeight: "1000", color: "#2b76d9", fontSize: "19px", paddingBottom: "1rem", marginTop: "0.6rem"}}>{`${Math.floor(examProgress)} % complete`}</Typography>
                        </div>
                    </Paper>
                </div>

                <div style={{height: "60vh", width: "100%"}}>
                    {
                        renderChapters()
                    }
                </div>

            </Grid>

            <Grid container xs={8.5} direction="row" 
            style={{
                height: "100vh",
                paddingTop: "30px", 
                alignItems: "center", 
                overflowX:"hidden", 
                overflowY: "scroll"}}>
                
                <Grid container item xs={12} spacing={2} class="search-wrapper"> 
                    <Grid xs={10} item container style={{width: "100%"}}> 
                        <Search 
                            material = {allMaterials}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} class="material-wrapper"> 
                    {exercise == null ? 
                    <Grid container class="chapter-wrapper"> 
                        <Grid item container style={{width: "100%"}}> 
                            {/* <Typography variant="h2" fontSize="2.15rem" fontWeight="500" style={{fontFamily: "Manrope"}}>
                                {allChaptersDict != null && `${allChaptersDict[chapterId].name} : `}
                                {material != null && material.materialName}
                            </Typography> */}
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="medium" />}
                                aria-label="breadcrumb"
                            >
                                {
                                     [
                                            <Typography key="2" color="text.primary" variant="h2" fontSize="1.7rem" fontWeight="500" style={{fontFamily: "Manrope"}}>
                                                {allChaptersDict != null && `${allChaptersDict[chapterId].name}`}
                                            </Typography>,
                                            <Typography key="2" color="text.primary" variant="h2" fontSize="1.7rem" fontWeight="500" style={{fontFamily: "Manrope"}}>
                                              {material != null && material.materialName}
                                            </Typography>
                                          ]
                                    
                                }
                            </Breadcrumbs>
                        </Grid>
                    </Grid> 
                    : null}
                    {
                        material != null 
                        ? material.materialType != null && material.materialType == "video"
                        ? material.videoSource == "vimeo" ? <div class="video_wrapper">
                            <Vimeo
                                video={material.videoUrl.substring(material.videoUrl.lastIndexOf("/")+1)}
                                className="vimeo-wrapper"
                                onEnd = { () => {
                                    let tempUserProgress = {...userProgress}
                                    if (tempUserProgress[examId] != null) {
                                        if (tempUserProgress[examId][chapterId] != null) {
                                            if (tempUserProgress[examId][chapterId][materialId] != null) {
                                                tempUserProgress[examId][chapterId][materialId]["hasWatched"] = true
                                            } else {
                                                tempUserProgress[examId][chapterId][materialId] = {
                                                    hasWatched : true
                                                }
                                            }
                                        } else {
                                            tempUserProgress[examId][chapterId] = {}
                                            tempUserProgress[examId][chapterId][materialId] = {
                                                    hasWatched : true
                                            }
                                        }
                                    } else {
                                        tempUserProgress[examId] = {}
                                        tempUserProgress[examId][chapterId] = {}
                                        tempUserProgress[examId][chapterId][materialId] = {
                                            hasWatched : true
                                        }
                                    }
                                    if (tempUserProgress["totalVideoProgress"] != null) {
                                        tempUserProgress["totalVideoProgress"][`${examId}_${chapterId}_${materialId}`] = true;
                                    } else {
                                        let progressKey = `${examId}_${chapterId}_${materialId}`
                                        tempUserProgress["totalVideoProgress"] = {};
                                        tempUserProgress["totalVideoProgress"][progressKey] = true;
                                    }
                                    setUserProgress(tempUserProgress)
                                    saveUserProgress()
                                }}
                            />
                        {/* <div style={{padding:"56.25% 0 0 0",position:"relative"}}>
                        <iframe 
                            src={"https://player.vimeo.com/video/"+  material.videoUrl.substring(material.videoUrl.lastIndexOf("/")+1) +"?title=0&byline=0&portrait=0"}
                            style={{position:"absolute", top:0,left:0,width:"100%", height:"100%"}} 
                            frameborder="0" 
                            webkitallowfullscreen 
                            mozallowfullscreen 
                            allowfullscreen></iframe>
                        
                        </div> */}
                        {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
                        </div>
                        : <div className="youtubevideo-container">
                            <YoutubeVideoPlayer 
                                videoUrl = {material.videoUrl.substring(material.videoUrl.lastIndexOf("/")+1)}
                                onEnd = { () => {
                                    let tempUserProgress = userProgress != null ? {...userProgress} : {}
                                    console.log("USERPROGRESSVIDEO", userProgress, tempUserProgress)
                                    if (tempUserProgress[examId] != null) {
                                        if (tempUserProgress[examId][chapterId] != null) {
                                            if (tempUserProgress[examId][chapterId][materialId] != null) {
                                                tempUserProgress[examId][chapterId][materialId]["hasWatched"] = true
                                            } else {
                                                tempUserProgress[examId][chapterId][materialId] = {
                                                    hasWatched : true
                                                }
                                            }
                                        } else {
                                            tempUserProgress[examId][chapterId] = {}
                                            tempUserProgress[examId][chapterId][materialId] = {
                                                    hasWatched : true
                                            }
                                        }
                                    } else {
                                        tempUserProgress[examId] = {}
                                        tempUserProgress[examId][chapterId] = {}
                                        tempUserProgress[examId][chapterId][materialId] = {
                                            hasWatched : true
                                        }
                                    }
                                    if (tempUserProgress["totalVideoProgress"] != null) {
                                        tempUserProgress["totalVideoProgress"][`${examId}_${chapterId}_${materialId}`] = true;
                                    } else {
                                        let progressKey = `${examId}_${chapterId}_${materialId}`
                                        tempUserProgress["totalVideoProgress"] = {};
                                        tempUserProgress["totalVideoProgress"][progressKey] = true;
                                    }

                                    setUserProgress(tempUserProgress)
                                    saveUserProgress(tempUserProgress)
                                }}
                            />
                        </div>
                        : material.materialType != null && material.materialType == "text" 
                        ? <Grid item container style={{width: "80%", height: "70vh"}}> 
                            <TinyMceEditor content={material.textMaterialHtml} save={()=>{}}/>
                            </Grid>
                        : null
                        : null
                    } 
                    {
                        exercise != null ?
                        isMaterialCompleted() 
                        ? 
                        <Grid container>
                            <Grid container item style={{width: "100%"}}>
                                <Typography>
                                    {`Question ${exerciseIndex+1} of ${totalExercises}` }
                                </Typography>
                            <LinearProgressBar value={
                                Math.floor(((exerciseIndex+1)/totalExercises) * 100)
                            } />
                            </Grid>
                            {exercise.exerciseType != null && exercise.exerciseType == "text"
                            ? <Grid item container direction="column" spacing={3} style={{marginTop: "20px"}}>
                            {
                            exercise.questions != null && exercise.questions.map((question, questionIndex) => {
                            return <Grid item container direction="column" spacing={3} style={{marginLeft: "20%"}}>
                                <Grid item>
                                <Typography variant="h7" component="h7" style={{fontWeight: "bold", fontSize: "1.3rem"}}>
                                {question.questionName}
                                </Typography>
                                </Grid>
                            {question.questionType == "single-choice"
                        && question.singleChoices != undefined 
                        && question.singleChoices != null
                        && question.singleChoices instanceof Array
                        && question.singleChoices.length != 0 
                        ? <Grid item><FormControl component="fieldset" style={{
                            width: "100%"
                        }}>
                            <RadioGroup 
                                aria-label="single-choices" 
                                name="single-choices" 
                                style={{width:"100%"}}
                                // value={value} 
                                // onChange={handleChange}
                                >
                                {question.singleChoices.map( (singleChoice, choiceIndex) => {
                                return <FormControlLabel
                                    style={{
                                        maxWidth:"40%",
                                        backgroundColor: "rgba(229, 231, 235, 0.4)",
                                        borderRadius: "8px",
                                        marginTop:"5px"
                                    }}
                                    classes={formLabelClass}
                                    checked={singleChoice.selected != undefined && singleChoice.selected == true}
                                    control={
                                    <Radio 
                                        onClick={() => {
                                            let tempquestions = [...exercise.questions]
                                            let singleChoices = [...tempquestions[questionIndex]["singleChoices"]]
                                            let ch = 0
                                            for (ch = 0; ch < singleChoices.length; ch =ch + 1) {
                                                singleChoices[ch]["selected"] = false;
                                            }
                                            singleChoices[choiceIndex]["selected"] = true;
                                            tempquestions[questionIndex]["singleChoices"] = singleChoices
                                            let tempExercise = {...exercise}
                                            tempExercise["questions"] = tempquestions
                                            setExercise(tempExercise)
                                        }}
                                    />} 
                                    label={
                                        <Grid container>
                                            <Grid item xs={10}>
                                    <Typography
                                        style={{
                                            height:"100%",
                                            color: "#505050"
                                        }}>
                                        {singleChoice.optionName}
                                    </Typography>
                                    </Grid>
                                    </Grid>
                                } />
                                })
                            }
                            </RadioGroup>
                            </FormControl>
                            </Grid>
                            : question.questionType == "multi-choice"
                            && question.multiChoices != undefined 
                            && question.multiChoices != null
                            && question.multiChoices instanceof Array
                            && question.multiChoices.length != 0 
                            ? <Grid item><FormControl component="fieldset" style={{
                                width: "100%"
                            }}>
                                <RadioGroup 
                                    aria-label="multi-choices" 
                                    name="multi-choices" 
                                    style={{width:"100%"}}
                                    // value={value} 
                                    // onChange={handleChange}
                                    >
                                    {question.multiChoices.map( (multiChoice, choiceIndex) => {
                                    return <FormControlLabel 
                                        style={{
                                            maxWidth:"40%",
                                        backgroundColor: "rgba(229, 231, 235, 0.4)",
                                        borderRadius: "8px",
                                        marginTop:"5px"
                                        }}
                                        classes={formLabelClass}
                                        checked={multiChoice.selected != null && multiChoice.selected == true}
                                        control={<Checkbox 

                                            onClick={() => {
                                                let tempquestions = [...exercise.questions]
                                                let multiChoices = [...tempquestions[questionIndex]["multiChoices"]]

                                                if (multiChoices[choiceIndex].selected != undefined
                                                    && multiChoices[choiceIndex].selected == true
                                                    ) {
                                                        multiChoices[choiceIndex].selected = false
                                                } else {
                                                    multiChoices[choiceIndex]["selected"] = true;
                                                }

                                                tempquestions[questionIndex]["multiChoices"] = multiChoices
                                                let tempExercise = {...exercise}
                                                tempExercise["questions"] = tempquestions
                                                setExercise(tempExercise)
                                            }}
                                        
                                        />} 
                                        label={
                                            <Grid container>
                                                <Grid item xs={10}>
                                        <Typography
                                            style={{
                                                height:"100%"
                                            }}>
                                            {multiChoice.optionName}
                                        </Typography>
                                        </Grid>
                                        </Grid>
                                    } />
                                    })
                                }
                                </RadioGroup>
                                </FormControl>
                                </Grid>
                                : question.questionType == "short-answer" 
                                    ?   <Grid item
                                    ><TextField
                                    style={{display: "flex"}}
                                    id="outlined-multiline-static"
                                    label="Multiline"
                                    multiline
                                    rows={4}
                                    defaultValue="Short Answer"
                                    variant="outlined"
                                    /></Grid>
                                    : null

                            }
                            </Grid>
                            })
                        }</Grid>
                        : exercise.exerciseType != null && exercise.exerciseType == "video" 
                        ? <Grid item container direction="column"> 
                                <Typography style={{marginTop: "30px", marginBottom: "20px"}} variant="body1" component="summary">{exercise.videoCaption}</Typography>
                            
                                <Paper elevation={10} style={{height: "60vh", borderRadius: "10px", padding:"1%"}}>
                                {
                                    exercise.videoUrl != null 
                                    && exercise.videoUrl != ""
                                    ? <YoutubeVideoPlayer 
                                        videoUrl = {exercise.videoUrl.substring(exercise.videoUrl.lastIndexOf("/")+1)}
                                    />
                                    : null
                                }
                                </Paper>
                            </Grid>
                        : null
                        }
                        <Grid item container style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            marginTop: "30px",
                            marginBottom:"100px"
                        }}>
                            {
                                exerciseIndex != null && exerciseIndex != 0 
                                ? <Button 
                                    onClick={() => {
                                        setExercise(exercisesSet[exerciseIndex - 1])
                                        setExerciseIndex(exerciseIndex - 1)
                                    }}
                                    variant="contained">
                                    Back
                                    </Button>
                                : null
                                }
                            {
                                exerciseIndex != null && totalExercises != null && exerciseIndex == totalExercises - 1 
                                ? <Button variant="contained">
                                    Submit
                                    </Button>
                                : <Button 
                                    variant="contained" 
                                    onClick={() => {
                                        setExercise(exercisesSet[exerciseIndex + 1])
                                        setExerciseIndex(exerciseIndex + 1)
                                    }}>
                                    Next
                                </Button>
                                }
                        </Grid>
                        </Grid>
                        : <Grid container style={{width: "100%", height : "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <img src={completeRest} style={{width: "50vw", height : "50vh"}} />
                            <Typography component="h2">Please complete all the materials in this chapter to take this Exercise</Typography>
                        </Grid>
                        : null
                    } 
                    {
                        material != null
                        && exercise == null 
                        ? <Box sx={{ width: '100%' }} style={{marginTop: "20px"}}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }} 
                        style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingRight: "10px", borderBottomWidth: 0}}>
                            <Tabs  value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="About" {...a11yProps(0)} />
                            {/* <Tab label="Progress" {...a11yProps(1)} /> */}
                            </Tabs>
                            
                            {
                                material.materialType != null && material.materialType == "text" ?
                                <Button variant="contained" endIcon={<KeyboardArrowRight />} 
                                onClick = { () => {
                                    let tempUserProgress = userProgress != null ? {...userProgress} : {}
                                    console.log("USERPROGRESSVIDEO", userProgress, tempUserProgress)
                                    if (tempUserProgress[examId] != null) {
                                        if (tempUserProgress[examId][chapterId] != null) {
                                            if (tempUserProgress[examId][chapterId][materialId] != null) {
                                                tempUserProgress[examId][chapterId][materialId]["hasWatched"] = true
                                            } else {
                                                tempUserProgress[examId][chapterId][materialId] = {
                                                    hasWatched : true
                                                }
                                            }
                                        } else {
                                            tempUserProgress[examId][chapterId] = {}
                                            tempUserProgress[examId][chapterId][materialId] = {
                                                    hasWatched : true
                                            }
                                        }
                                    } else {
                                        tempUserProgress[examId] = {}
                                        tempUserProgress[examId][chapterId] = {}
                                        tempUserProgress[examId][chapterId][materialId] = {
                                            hasWatched : true
                                        }
                                    }
                                    if (tempUserProgress["totalVideoProgress"] != null) {
                                        tempUserProgress["totalVideoProgress"][`${examId}_${chapterId}_${materialId}`] = true;
                                    } else {
                                        let progressKey = `${examId}_${chapterId}_${materialId}`
                                        tempUserProgress["totalVideoProgress"] = {};
                                        tempUserProgress["totalVideoProgress"][progressKey] = true;
                                    }

                                    setUserProgress(tempUserProgress)
                                    saveUserProgress(tempUserProgress)
                                }} >
                                Complete
                            </Button>
                            : null
                            }
                        </Box>
                        <TabPanel value={tabValue} index={0}>
                            { material != null && material.videoCaption}
                        </TabPanel>
                        {/* <TabPanel value={tabValue} index={1}>
                            <Grid container direction="row" spacing={3}>
                                <Grid container item xs={3} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <Typography style={{marginBottom: 10}}>Chapter Progress</Typography>
                                    <CircularStatic value={60}/>
                                </Grid>
                                <Grid container item xs={3} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <Typography style={{marginBottom: 10}}>Total Progress</Typography>
                                    <CircularStatic  value={90}/>
                                </Grid>
                            </Grid>
                        </TabPanel> */}
                        </Box>
                        : null
                    }
                    </Grid>
                

            </Grid>
            </Grid>

        </div>
    )
}

export default CoursePage;