import React from 'react';
import AppRoot from "./AppRoot";
import AppLoader from "./AppLoader";
import { SettingsProvider } from './contexts/SettingsContext';
import { AuthProvider } from './contexts/AuthContext';



function App(){
  return(
    <AppRoot>
      <SettingsProvider>
      <AuthProvider>
      <AppLoader />
      </AuthProvider>
      </SettingsProvider>
    </AppRoot>
  )
}

export default App;